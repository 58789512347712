





































































































































import { Vue, Component } from "vue-property-decorator";
import { CarouselItem } from "@/types/components/carousel";
import Carousel from "@/components/Carousel.vue";
import { CompanyCareer, CompanyServiceModal } from "@/types/company";
import { ExpansionPanelItem } from "@/types/components/expansionpanel";

import ServiceCard from "@/components/company/services/ServiceCard.vue";
import ServiceModal from "@/components/company/services/ServiceModal.vue";

@Component({
    components: {
        Carousel,
        ServiceCard,
        ServiceModal
    }
})
export default class Career extends Vue {
    private mounted() {
        if (!this.$route.hash) return;

        const el = document.querySelector(this.$route.hash);
        if (!el) return;
        el.scrollIntoView();
    }

    private carouselItems: CarouselItem[] = [
        {
            imageSrc: require("@/assets/img/career/Slider.png"),
            heading: "",
            subheading: "",
            button: null
        }
    ];

    private companyCareers: CompanyCareer[] = [
        {
            title: "Fachinformatiker/in Systemintegration (m/w/d)",
            text:
                "Du denkst in Nullen und Einsen? DHCP, DNS und NAT klingen für dich nicht nach neuen deutschen Parteien? Dann ist der Beruf des Fachinformatikers für Systemintegration bestimmt etwas für dich! Wir suche dich ab sofort als Fachinformatiker/in Systemintegration, mach deine Ausbildung bei uns!",
            imageSrc: require("@/assets/img/career/SystemintegratorAzubi.png"),
            button: null
        },
        {
            title: "Fachinformatiker/in Anwendungsentwicklung (m/w/d)",
            text:
                "Software fasziniert dich? Du hast die schnellsten Finger jenseits des wilden Westens? Deine liebsten Fremdsprachen sind C++ und Java? Wie wärs mit einer Ausbildung zum Fachinformatiker für Anwendungsentwicklung?",
            imageSrc: require("@/assets/img/career/Anwendungsentwicklung.png"),
            button: null
        },
        {
            title: "IT-Systemelektroniker/in (m/w/d)",
            text:
                "Spaghetti-Verkabelung treibt dich in den Wahnsinn? Informations- und Telekommunikationstechnik begeistert dich? Dein Heimnetzwerk hast du voll im Griff? Dann bist du der geborene IT-Systemelektroniker!",
            imageSrc: require("@/assets/img/career/IT-Systemelektroniker.png"),
            button: null
        },
        {
            title:
                "Kaufmann/-frau für IT-Systemmanagement mit Mediengestalter/in Eigenschaften (m/w/d)",
            text:
                "Du hast ein Händchen für IT? Microsoft Office kennst du so gut wie deine Westentasche? Du erstellst gerne digitale Graphiken? Du bist ein kommunikatives Talent? Dann schau dir doch mal den Ausbildungsberuf des IT-Systemkaufmannes an!",
            imageSrc: require("@/assets/img/career/IT-Systemkaufmann.png"),
            button: null
        }
        // {
        //     title: "Mediengestalter/in (m/w/d)",
        //     text: "Du bist ein kreatives Köpfchen und bringst ein gewisses gestalterisches Talent mit? Du hast Lust deine eigenen Ideen in Formen, Farben, Bildern und Typografien umzusetzen? Dann bewerbe dich für deine Ausbildung als Mediengestalter/in!",
        //     imageSrc: require("@/assets/img/career/Mediengestalter.png"),
        // },
    ];

    private companyCareersEmployee: CompanyCareer[] = [
        {
            title: "Fachinformatiker/in Systemintegration (m/w/d)",
            text:
                "Du denkst in Nullen und Einsen? DHCP, DNS und NAT klingen für dich nicht nach neuen deutschen Parteien? Wir suche dich ab sofort als Fachinformatiker/in Systemintegration auf Vollzeit! ",
            imageSrc: require("@/assets/img/career/SystemintegratorVollzeit.png"),
            button: null
        },
        {
            title: "IT-Systemelektroniker/in (m/w/d)",
            text:
                "Du hast ein Händchen für IT? Microsoft Office kennst du so gut wie deine Westentasche? Du bist ein kommunikatives Talent? Wir suchen dich ab sofort als IT-Systemelektroniker auf Vollzeit!",
            imageSrc: require("@/assets/img/career/SystemelektronikerVollzeit.png"),
            button: null
        }
    ];

    private companyServiceModal: CompanyServiceModal = {
        show: false,
        header: "",
        expansionPanelItems: []
    };

    private showCompanyServiceModal(service: string) {
        const expansionPanelItems: ExpansionPanelItem[] = [];
        const modalHeader = service;

        switch (service.toLowerCase()) {
            case "fachinformatiker/in systemintegration (m/w/d)":
                expansionPanelItems.push({
                    header: "Qualifikationen und Vorraussetzungen",
                    content: [
                        {
                            header: "Qualifikationen und Vorraussetzungen",
                            text:
                                "Interesse an Computer und IT - gutes Verständnis für Zahlen und Logik - gute Englischkenntnisse - Realschulabschluss oder Abitur."
                        }
                    ]
                });
                expansionPanelItems.push({
                    header: "Was Sie bei uns erwartet",
                    content: [
                        {
                            header: "Was Sie bei uns erwartet",
                            text:
                                "Langfristiger Arbeitsplatz in einem lockeren Team - Eigenverantwortliches Arbeiten mit flexibler Aufgabeneinteilung - Leistungsgerechte Vergütung - Projektprämien - Sonderbezahlung von Überstunden"
                        }
                    ]
                });
                break;
            case "fachinformatiker/in anwendungsentwicklung (m/w/d)":
                expansionPanelItems.push({
                    header: "Qualifikationen und Vorraussetzungen",
                    content: [
                        {
                            header: "Qualifikationen und Vorraussetzungen",
                            text:
                                "Mathematische Fähigkeiten - Technisches Verständnis - Logisches Denken - Sorgfältiges und genaues Arbeiten - Realschulabschluss oder Abitur."
                        }
                    ]
                });
                expansionPanelItems.push({
                    header: "Was Sie bei uns erwartet",
                    content: [
                        {
                            header: "Was Sie bei uns erwartet",
                            text:
                                "Langfristiger Arbeitsplatz in einem lockeren Team - Eigenverantwortliches Arbeiten mit flexibler Aufgabeneinteilung - Leistungsgerechte Vergütung - Projektprämien - Sonderbezahlung von Überstunden"
                        }
                    ]
                });
                break;
            case "it-systemelektroniker/in (m/w/d)":
                expansionPanelItems.push({
                    header: "Qualifikationen und Vorraussetzungen",
                    content: [
                        {
                            header: "Qualifikationen und Vorraussetzungen",
                            text:
                                "Gute Kenntnisse in Mathematik, Physik, Informatik und Englisch - Technisches und handwerkliches Verständnis - Logisches und analytisches Denken - Selbstständige, konzentrierte und genaue Arbeitsweise."
                        }
                    ]
                });
                expansionPanelItems.push({
                    header: "Was Sie bei uns erwartet",
                    content: [
                        {
                            header: "Was Sie bei uns erwartet",
                            text:
                                "Langfristiger Arbeitsplatz in einem lockeren Team - Eigenverantwortliches Arbeiten mit flexibler Aufgabeneinteilung - Interresante Aufgaben - Leistungsgerechte Vergütung - Mitarbeiter Equipment"
                        }
                    ]
                });
                break;
            case "it-systemkaufmann/frau (m/w/d)":
                expansionPanelItems.push({
                    header: "Qualifikationen und Vorraussetzungen",
                    content: [
                        {
                            header: "Qualifikationen und Vorraussetzungen",
                            text:
                                "Interesse an Technik und IT-Systemen - Gute Noten in Mathe, Englisch und Informatik - guter Realschulabschluss oder Abitur"
                        }
                    ]
                });
                expansionPanelItems.push({
                    header: "Was Sie bei uns erwartet",
                    content: [
                        {
                            header: "Was Sie bei uns erwartet",
                            text:
                                "Langfristiger Arbeitsplatz in einem lockeren Team - Eigenverantwortliches Arbeiten mit flexibler Aufgabeneinteilung - Leistungsgerechte Vergütung - Projektprämien - Sonderbezahlung von Überstunden"
                        }
                    ]
                });
                break;
            //TODO no instructor for trainee´s
            //            case "mediengestalter/in (m/w/d)":
            //                expansionPanelItems.push({
            //                  header: "Qualifikationen und Vorraussetzungen",
            //                  content: [
            //                    {
            //                      //TODO Change Text
            //                    header: "Qualifikationen und Vorraussetzungen",
            //                  text: "testtest teste test",
            //                 },
            //             ],
            //        });
            //       expansionPanelItems.push({
            //         header: "Was Sie bei uns erwartet",
            //       content: [
            //           {
            //              //TODO Change Text
            //             header: "Was Sie bei uns erwartet",
            //            text: "test teste teste",
            //        },
            //   ],
            //  });
        }

        this.companyServiceModal.header = modalHeader;
        this.companyServiceModal.expansionPanelItems = expansionPanelItems;
        this.companyServiceModal.show = true;
    }
    private employeeBenefits = [
        "Spannende IT/ITK-Themen",
        "großes IT-Portfolio",
        "moderne IT-Infrastruktur",
        "moderne Arbeitsgeräte - Notebooks, Smartphones und mehr",
        "moderne Arbeitsplätze",
        "mehrere Standorte",
        "Weiterbildung",
        "bereichsübergreifender Wissensaustausch",
        "Karriere",
        "Dienstfahrzeuge",
        "nette Kollegen (m/w/d)",
        "Teamwork",
        "Arbeitszeitmodelle",
        "gehobene Umgangskultur",
        "Getränke, Obst und mehr",
        "Teamevents",
        "Chefsprechstunden",
        "Outdoorpausenbereich",
        "Altersvorsorge BAV",
        "Betriebliche Krankenversicherung BKV"
    ];
}
